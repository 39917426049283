import { Controller } from "@hotwired/stimulus";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import { Japanese } from "flatpickr/dist/l10n/ja";
import KTDualListbox from "../customs/dual-listbox";
export default class extends Controller {
    connect() {
        this.datePicker();
        if ($("#kt_dual_listbox_4").length > 0) {
            KTDualListbox.init();
        }

        $("#record_year").select2();
        $("#record_year").on("select2:select", function () {
            let event = new Event("change", { bubbles: true }); // fire a native event
            this.dispatchEvent(event);
        });
    }

    datePicker = function () {
        /* Date of Measurement */
        if (document.getElementById("date_of_measurement")) {
            if (document.getElementById("date_of_measurement").value) {
                flatpickr(".date_of_measurement_datepicker", {
                    wrap: true,
                    locale: Japanese,
                    maxDate: "today",
                    disableMobile: "true",
                    allowInvalidPreload: true,
                    altInput: true,
                    altFormat: "Y年m月d日"
                });
            } else {
                flatpickr(".date_of_measurement_datepicker", {
                    wrap: true,
                    locale: Japanese,
                    defaultDate: "today",
                    maxDate: "today",
                    disableMobile: "true",
                    allowInvalidPreload: true,
                    altInput: true,
                    altFormat: "Y年m月d日",
                });
            }
        }

        /* Daily Monthly Date of Measurement */
        if (document.getElementById("monthly_date_of_measurement")) {
            if (document.getElementById("monthly_date_of_measurement").value) {
                flatpickr(".monthly_date_of_measurement_datepicker", {
                    wrap: true,
                    locale: Japanese,
                    disableMobile: "true",
                    allowInvalidPreload: true,
                    altInput: true,
                    altFormat: "Y年m月d日",
                    maxDate: "today",
                    plugins: [
                        new monthSelectPlugin({
                            shorthand: true,
                            dateFormat: "Y-m",
                            altFormat: "Y年m月",
                            theme: "light",
                        }),
                    ]
                });
            } else {
                flatpickr(".monthly_date_of_measurement_datepicker", {
                    wrap: true,
                    locale: Japanese,
                    defaultDate: "today",
                    disableMobile: "true",
                    allowInvalidPreload: true,
                    altInput: true,
                    altFormat: "Y年m月d日",
                    maxDate: "today",
                    plugins: [
                        new monthSelectPlugin({
                            shorthand: true,
                            dateFormat: "Y-m",
                            altFormat: "Y年m月",
                            theme: "light",
                        }),
                    ]
                });
            }
        }

    }

    /* Daily Date of Measurement Change Event */
    measureDateChange(event) {
        window.location = "/vital_details/daily_data_input?sel_id=" + $("#selected_id").val() + "&record_date=" + event.target.value;
    }

    /* Monthly Date of Measurement Change Event */
    monthlyMeasureDateChange(event) {
        window.location = "/vital_details/monthly_data_input?sel_id=" + $("#selected_id").val() + "&record_date=" + event.target.value;
    }

    goToInputScreen() {
        var selectedVitalIDArray = []
        for (let i = 0; i< $(".dual-listbox__selected").children().length; i++) {
            selectedVitalIDArray.push($(".dual-listbox__selected").children()[i].dataset.id)
        }
        // set cookies 
        document.cookie = "sel_m_vitals=" + selectedVitalIDArray + ';path=/';
    }

    // Vital Data Analysis Monthly Total
    vitalMonthlyTotalMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/vital_analysis_monthly_provider?record_month=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/vital_analysis_monthly_provider?record_month=" + event.target.value;
        }
    }

    // Vital Data Analysis Monthly Change
    vitalMonthlyChangeMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/vital_analysis_yearly_provider?record_year=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/vital_analysis_yearly_provider?record_year=" + event.target.value;
        }
    }

    vitalMonthlyTrend(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val();
            window.location = "/analysis/vital_analysis_yearly_provider?record_year=" + event.target.value + "&type=onlyTwo" + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/vital_analysis_yearly_provider?record_year=" + event.target.value + "&type=onlyTwo";
        }
    }
    

        // Vital Data Analysis Monthly Total New
        vitalMonthlyTotalMeasureDateChangeNew(event) {
            if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
                var selectedID = $("#sel_id").val()
                window.location = "/analysis/vital_analysis_monthly_total_for_step_and_duration?record_month=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
            } else {
                window.location = "/analysis/vital_analysis_monthly_total_for_step_and_duration?record_month=" + event.target.value;
            }
        }
    
        // Vital Data Analysis Monthly Change New
        vitalMonthlyChangeMeasureDateChangeNew(event) {
            if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
                var selectedID = $("#sel_id").val()
                window.location = "/analysis/vital_analysis_monthly_change_new?record_year=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
            } else {
                window.location = "/analysis/vital_analysis_monthly_change_new?record_year=" + event.target.value;
            }
        }

    // Event Data Analysis Monthly Total
    eventMonthlyTotalMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/event_analysis_monthly_total?record_month=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/event_analysis_monthly_total?record_month=" + event.target.value;
        }
    }

    // Event Data Analysis Monthly Change
    eventMonthlyChangeMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/event_analysis_monthly_change?record_year=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/event_analysis_monthly_change?record_year=" + event.target.value;
        }
    }

    // Point Data Analysis Monthly Total
    pointMonthlyTotalMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/point_analysis/point_analysis_monthly_provider?record_month=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/point_analysis/point_analysis_monthly_provider?record_month=" + event.target.value;
        }
    }

    // Point Data Analysis Monthly Change
    pointMonthlyChangeMeasureDateChange(event) {
        if ($("#display_type").val() != "1" && $("#display_type").val() != "") {
            var selectedID = $("#sel_id").val()
            window.location = "/analysis/point_analysis/point_analysis_yearly_provider?record_year=" + event.target.value + "&sel_id=" + selectedID + "&display_type=" + $("#display_type").val();
        } else {
            window.location = "/analysis/point_analysis/point_analysis_yearly_provider?record_year=" + event.target.value;
        }
    }
}
